import { useEffect } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import {
	useForm as useHookForm,
	UseFormProps as UseHookFormProps,
} from 'react-hook-form'
import { ZodSchema, TypeOf } from 'zod'

interface UseFormProps<T extends ZodSchema<any>>
	extends UseHookFormProps<TypeOf<T>> {
	schema: T
	name?: string
	isCvMandatory?: boolean
	scrollToError?: boolean
}

/**
 * @description a hook for handling the useHookForm
 * @returns useHookForm with a resolver and mode
 * @param schema ZodSchema
 */
export const useForm = <T extends ZodSchema<any>>({
	schema,
	name,
	isCvMandatory,
	scrollToError = true,
	...formConfig
}: UseFormProps<T>) => {
	const formHookState = useHookForm({
		resolver: zodResolver(schema),
		mode: 'onTouched',
		...formConfig,
	})

	const {
		formState: { errors, isSubmitting, isSubmitted },
	} = formHookState

	useEffect(() => {
		if (!scrollToError) {
			return
		}

		if (isSubmitting || isSubmitted) {
			const fieldErrors = Object.keys(errors)
				.filter(Boolean)
				.map((fieldName) => {
					if (name) {
						const formElement = document.querySelector(`[name=${name}]`)

						return formElement?.querySelector(`[name=${fieldName}]`) as Element
					}

					if (fieldName === 'cv' && isCvMandatory) {
						const cvElement = document.querySelector(
							`[name=${fieldName}]`
						) as Element

						return cvElement.parentElement as Element
					}

					return document.querySelector(`[name=${fieldName}]`) as Element
				})
				.filter((element) => !!element)

			if (fieldErrors.length) {
				const sortedElements = fieldErrors.sort(
					(a, b) =>
						a.getBoundingClientRect().top - b.getBoundingClientRect().top
				)

				sortedElements[0].scrollIntoView({
					behavior: 'smooth',
					block: 'center',
				})
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSubmitting, isSubmitted])

	return formHookState
}
