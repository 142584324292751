import { useDevice } from 'common'
import { Button, Noop, Container, OptimizedImage } from 'ui'
import { ImageHeaderProps } from './ImageHeader.data'

export const ImageHeader = ({
	tag,
	type,
	title,
	description,
	primaryButtonUrl,
	primaryButtonText,
	secondaryButtonUrl,
	secondaryButtonText,
	imageDesktop,
	imageMobile,
	imageTablet,
	scrollId,
	theme,
}: ImageHeaderProps) => {
	const device = useDevice()

	const getImage = () => {
		if (device === 'mobile' && imageMobile) {
			return imageMobile
		}
		if (device === 'tablet' && imageTablet) {
			return imageTablet
		}
		return imageDesktop || ''
	}

	const generateButtons = () => {
		if (!primaryButtonText && !secondaryButtonText) {
			return <Noop />
		}

		return (
			<div className="mt-8 flex gap-2 sm:w-[263px] sm:flex-col">
				{secondaryButtonText && (
					<Button href={secondaryButtonUrl} theme="light" variant="secondary">
						{secondaryButtonText}
					</Button>
				)}
				{primaryButtonText && (
					<Button href={primaryButtonUrl} theme={theme || 'colored'}>
						{primaryButtonText}
					</Button>
				)}
			</div>
		)
	}

	return (
		<div
			className="section relative sm:h-[560px] md:h-[720px] lg:h-[720px]"
			id={scrollId}
		>
			<OptimizedImage
				alt={title || ''}
				src={getImage()}
				className="object-cover"
				fill
				sizes="100vw"
			/>
			<div className="bg-black-alpha/[0.24] relative flex h-full items-center">
				<Container className="flex flex-col items-center" isNarrow>
					{type === 'header' ? (
						<h1 className="text-center">
							{tag && <span className="title_medium text-white">{tag}</span>}
							<span className="block font-extrabold text-white sm:text-[56px] sm:leading-[72px] md:text-[88px] md:leading-[104px] lg:text-[104px] lg:leading-[120px]">
								{title}
							</span>
						</h1>
					) : (
						<>
							{tag && <h4 className="title_medium text-white">{tag}</h4>}
							<h3 className="text-center font-extrabold text-white sm:text-[56px] sm:leading-[72px] md:text-[88px] md:leading-[104px] lg:text-[104px] lg:leading-[120px]">
								{title}
							</h3>
						</>
					)}

					{description && (
						<div className="subtitle_highlight text-center text-white">
							{description}
						</div>
					)}
					{generateButtons()}
				</Container>
			</div>
		</div>
	)
}
