import React, { ComponentProps } from 'react'
import { ZodObject, ZodRawShape } from 'zod'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import {
	RichtextContent,
	RichtextContentSchema,
	StoryblokAccordion,
	StoryblokAccordionSchema,
	StoryblokSectionHeader,
	StoryblokSectionHeaderSchema,
	StoryblokSliderSchema,
} from 'ui'
import { isLocal } from 'configs'
import { StoryContentSchema } from 'schema'
import Grid, { GridSchema } from '@components/ui/Grid'
import StoryblokCarousel from '@components/ui/StoryblokCarousel'
import InfoCard, { InfoCardSchema } from '@components/ui/InfoCard'
import {
	StoryblokImageGallery,
	StoryblokImageGallerySchema,
} from '@components/ui/ImageGallery'
import { StoryblokTeamCardSchema } from 'ui/molecules/TeamCard/StoryblokTeamCard/StoryblokTeamCard.schema'
import { StoryblokTeamCard } from 'ui/molecules/TeamCard/StoryblokTeamCard/StoryblokTeamCard'
import EmailForm from '@components/ui/EmailForm'
import { EmailFormSchema } from '@components/ui/EmailForm/EmailForm.schema'
import PageSection, { PageSectionSchema } from '@components/ui/PageSection'

const ComponentsWithSchema: Record<
	string,
	{
		component: (props: any) => JSX.Element
		schema: ZodObject<ZodRawShape>
	}
> = {
	accordion: {
		component: StoryblokAccordion,
		schema: StoryblokAccordionSchema,
	},
	carousel: {
		component: StoryblokCarousel,
		schema: StoryblokSliderSchema,
	},
	grid: {
		component: Grid,
		schema: GridSchema,
	},
	image_gallery: {
		component: StoryblokImageGallery,
		schema: StoryblokImageGallerySchema,
	},
	info_card: {
		component: InfoCard,
		schema: InfoCardSchema,
	},
	section_header: {
		component: StoryblokSectionHeader,
		schema: StoryblokSectionHeaderSchema,
	},
	team_card: {
		component: StoryblokTeamCard,
		schema: StoryblokTeamCardSchema,
	},
	email_form: {
		component: EmailForm,
		schema: EmailFormSchema,
	},
	richtext_content: {
		component: RichtextContent,
		schema: RichtextContentSchema,
	},
	page_section: {
		component: PageSection,
		schema: PageSectionSchema,
	},
}

export const Components = Object.entries(ComponentsWithSchema).reduce(
	(acc, [key, { component }]) => {
		return {
			...acc,
			[key]: component,
		}
	},
	{}
)

type DynamicComponentProps = {
	blok: any
	parent?: string
	columnsConfig?: object
	index?: number
} & ComponentProps<'div'>

const DynamicComponent = ({ blok, ...props }: DynamicComponentProps) => {
	const { schema, component } = ComponentsWithSchema[blok.component] ?? {}
	const { asPath, locale } = useRouter()
	const { t } = useTranslation()

	if (typeof component === 'undefined') {
		return React.createElement(
			() => <div>The component {blok.component} has not been created yet.</div>,
			{ key: blok._uid }
		)
	}

	if (isLocal) {
		return React.createElement(component, {
			key: blok._uid,
			blok,
			t,
			...props,
		})
	}

	const result = schema.extend(StoryContentSchema).safeParse(blok)

	if (!result.success) {
		throw new Error(
			`Schema is invalid for ${
				blok.component
			} at route ${locale}${asPath}: ${result.error.toString()}`
		)
	}

	const { data: parsedBlok } = result

	return React.createElement(component, {
		key: parsedBlok._uid,
		blok: parsedBlok,
		t,
		...props,
	})
}

export default DynamicComponent
