import { ComponentPropsWithoutRef, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { useFormContext } from 'react-hook-form'
import clsx from 'clsx'
import { convertToMegabytes, fileToBase64, getExtension } from 'common'
import { Button, FieldLabel, UploadIcon } from 'ui'
import MultiUploadTooltip from './MultiUploadTooltip'
import FilePreview from './FilePreview'

type UploadOptions = {
	name: string
	title: string
	file: any
	isRequired?: boolean
}

const acceptedFileTypes =
	'.rtf,.doc,.docx,.docm,.dot,.pdf,.xls,.xlsx,.ppt,.pptx,.txt,.xml,.odt,.ods,.json,.html'

const FILE_SIZE_LIMIT = 5
const TOTAL_SIZE_LIMIT = 10

interface MultiUploadProps extends ComponentPropsWithoutRef<'div'> {
	label?: string
}

const MultiUpload = ({ label, ...props }: MultiUploadProps) => {
	const { t } = useTranslation()
	const [totalFileSizeMB, setTotalFileSizeMB] = useState(0)
	const { setValue, getValues, getFieldState, trigger } = useFormContext()
	const { current: currentRef } = useRef<Array<HTMLInputElement>>([])
	const [errorMessage, setErrorMessage] = useState<Record<string, string>>({})

	const { invalid } = getFieldState('cv')

	const uploadOptions: Array<UploadOptions> = [
		{
			name: 'diploma',
			title: t('Diploma / References'),
			file: getValues('diploma'),
		},
		{
			name: 'other',
			title: t('Custom Document 01'),
			file: getValues('other'),
		},
	]

	const onInput = async ({ name, title }: UploadOptions) => {
		const fileInput = document.getElementById(title) as HTMLInputElement
		const files = fileInput.files

		if (files === null || files.length === 0) {
			return
		}

		const file = files[0]
		const fileSizeInMB = convertToMegabytes(file.size)

		if (!acceptedFileTypes.includes(getExtension(file.name))) {
			const error = {
				[name]: t('Invalid file type!'),
			}
			setErrorMessage((prev) => ({ ...prev, ...error }))
		} else if (
			totalFileSizeMB + fileSizeInMB > TOTAL_SIZE_LIMIT ||
			fileSizeInMB > FILE_SIZE_LIMIT
		) {
			const error = {
				[name]: t('File size too big!'),
			}
			setErrorMessage((prev) => ({ ...prev, ...error }))
		} else {
			const base64string = await fileToBase64(file)
			if (base64string) {
				setTotalFileSizeMB(
					(prev) => prev + convertToMegabytes(base64string.length)
				)
				setValue(name, {
					fileName: file.name,
					content: base64string,
				})
				trigger(name)
				setErrorMessage((prev) => ({ ...prev, [name]: '' }))
			} else {
				const error = {
					[name]: t("Couldn't upload this file!"),
				}
				setErrorMessage((prev) => ({ ...prev, ...error }))
			}
		}
	}

	const onRemove = (name: string, fileSize: number) => {
		setValue(name, undefined)
		setTotalFileSizeMB((prev) => prev - convertToMegabytes(fileSize))
	}

	useEffect(() => {
		if (invalid) {
			setErrorMessage((prev) => ({ ...prev, cv: t('CV is mandatory') }))
		}
	}, [invalid, t])

	const generateUploadRow = () =>
		uploadOptions.map((row: UploadOptions, idx: number) => {
			const error = errorMessage[row.name]

			return (
				<div
					key={row.name}
					className={clsx(
						'even:bg-gray-10 flex w-full justify-between gap-x-4 px-4 py-2 sm:flex-col sm:gap-x-4 sm:gap-y-2 md:items-center lg:items-center',
						row.file && 'sm:px-0 sm:pl-4'
					)}
				>
					<span className="flex flex-col">
						<p
							className={clsx(
								'subtitle_highlight w-fit',
								error && 'text-status-error'
							)}
						>
							{row.title}
							{row.isRequired ? '*' : ''}
						</p>
						{error && (
							<p className="text-status-error micro_highlight">{error}</p>
						)}
					</span>
					<input
						name={row.name}
						id={row.title}
						ref={(element) => {
							currentRef[idx] = element as HTMLInputElement
						}}
						type="file"
						accept={acceptedFileTypes}
						className="hidden"
						onChange={async () => await onInput(row)}
					/>
					{!row.file && (
						<Button
							id={row.title}
							variant="secondary"
							icon={<UploadIcon />}
							iconPosition="right"
							onClick={() => currentRef[idx].click()}
							className={clsx(error && 'text-status-error border-status-error')}
						>
							<p className="p_highlight">{t('Upload')}</p>
						</Button>
					)}
					{row.file && (
						<div className="max-w-[240px] sm:max-w-[128px] md:max-w-[208px]">
							<FilePreview
								file={row.file}
								onRemove={() => onRemove(row.name, row.file.content.length)}
							/>
						</div>
					)}
				</div>
			)
		})

	return (
		<div {...props}>
			{label && <FieldLabel text={label} />}
			<div className="border-gray-25 flex h-auto w-full flex-col gap-y-10 rounded-sm border-4 border-dashed px-6 py-10 sm:gap-y-6 sm:px-4 sm:py-6 md:gap-y-6 md:px-4 md:py-6">
				<div className="flex items-center justify-between gap-x-2 px-4">
					<h3 className="title">{t('Upload your files')}</h3>
					<MultiUploadTooltip>
						<div className="micro_highlight w-[272px] rounded bg-white p-2 shadow-[2px_5px_15px_4px_#00000016]">
							{t('Accepted formats')}:
							<br />
							{acceptedFileTypes.replace(/\./g, ' ')}
						</div>
					</MultiUploadTooltip>
				</div>
				<div className="flex flex-col gap-y-2">{generateUploadRow()}</div>
				<div className="flex flex-col justify-center px-6">
					<p className="micro text-gray-70 mb-4 text-center">
						{t(
							'Maximum upload size: {{fileSize}}MB / file, {{totalSize}}MB total.',
							{
								fileSize: FILE_SIZE_LIMIT,
								totalSize: TOTAL_SIZE_LIMIT,
							}
						)}
					</p>
				</div>
			</div>
		</div>
	)
}

export default MultiUpload
