import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import { deAT, enUS } from 'date-fns/locale'
import { Control } from 'react-hook-form'
import { StoryblokRichtext } from 'storyblok-rich-text-react-renderer'
// import RichTextResolverService from 'service/RichTextResolverService'
// import { FormItem, FormItemGroup } from 'service/StoryblokService'
// import ControlledPhoneInput from '../ControlledPhoneInput'
import {
	Noop,
	ControlledCheckbox,
	ControlledInput,
	ControlledRadio,
	ControlledTextarea,
	ControlledPhoneInput,
	ControlledSelect,
	FormTitle,
	ControlledDatePicker,
} from 'ui'
import { FormItem, FormItemGroup } from 'service'
import RichTextResolverService from '@service/RichTextResolverService'
import ControlledMultiUpload from '../MultiUpload/ControlledMultiUpload/ControlledMultiUpload'
import { useRouter } from 'next/router'
// import ControlledImageUpload from '../Dropzone/ImageUpload/ControlledImageUpload'
// import ControlledDocumentUpload from '../Dropzone/DocumentUpload/ControlledDocumentUpload'
// import { toI18nLanguage } from '@utils/locale'
// import ModalCheckbox from '../ModalCheckbox/ModalCheckbox'
// import ControlledMultiUpload from '../Dropzone/MultiUpload/ControlledMultiUpload/ControlledMultiUpload'
// import ControlledDatePicker from '../DatePicker/ControlledDatePicker'
// import TextAccordion from '../TextAccordion'
// import FormAssistantBlock from '../FormAssistantBlock'
// import { Picklist } from '../Picklist'
// import { Job } from 'service/AlgoliaService'

const resolveLabel = (
	label?: StoryblokRichtext,
	optionalText?: string,
	isRequired?: boolean
) => {
	if (!label) {
		return ''
	}

	const appendText = isRequired ? '*' : optionalText

	let res = new RichTextResolverService(label).resolveFormItemLabel()

	if (typeof res === 'string') {
		res += appendText
	} else {
		const { props: _props, key: _key, ...rest } = res[0]
		rest.props = { children: [appendText] }
		rest.key = '999'
		res.push(rest)
	}

	return res
}

interface DynamicFormProps {
	formData: Array<FormItem | FormItemGroup>
	control?: Control
	className?: string
	wrapperClass?: string
}

const verticalSpacingClass = 'lg:mb-8 md:mb-8 sm:mb-6'

interface GeneratorProps extends FormItem {
	control?: Control
	className?: string
	noOptionalText?: boolean
	formData: Array<FormItem | FormItemGroup>
}

const Generator = ({
	// formData,
	type,
	api_field_name,
	// picklist_field_name,
	label,
	description,
	options,
	placeholder,
	control,
	className,
	required,
	noOptionalText,
	start_year,
	end_year,
	max_length,
	width,
}: // component,
GeneratorProps) => {
	const { t } = useTranslation()

	// const fixedT = i18n.getFixedT(toI18nLanguage(locale))

	const { locale } = useRouter()

	const optional = noOptionalText ? '' : ` (optional)`

	// if (component === 'assistant_block') {
	// 	return (
	// 		<div className="col-span-full mb-2">
	// 			<FormAssistantBlock formData={formData} />
	// 		</div>
	// 	)
	// }

	const labelCamelCase = new RichTextResolverService(label).resolveToCamelCase()
	api_field_name ||= labelCamelCase

	switch (type) {
		case 'text': {
			return (
				<ControlledInput
					wrapperClass={clsx(verticalSpacingClass, className)}
					label={resolveLabel(label, optional, required)}
					hint={description}
					maxLength={max_length}
					name={api_field_name}
					placeholder={placeholder}
				/>
			)
		}
		case 'textarea': {
			return (
				<ControlledTextarea
					name={api_field_name}
					placeholder={placeholder}
					className="h-80"
					maxLength={max_length}
					wrapperClass={clsx('col-span-full', className)}
					label={resolveLabel(label, optional, required)}
				/>
			)
		}
		case 'number': {
			return (
				<ControlledInput
					name={api_field_name}
					label={resolveLabel(label, optional, required)}
					placeholder={placeholder}
					hint={description}
					wrapperClass={clsx(verticalSpacingClass, className)}
					type="number"
				/>
			)
		}
		case 'calendar': {
			const today = new Date()
			const start = start_year
				? new Date(`01/01/${start_year}`)
				: new Date('01/01/1950')
			const end = end_year
				? new Date(`12/31/${end_year}`)
				: new Date(today.setMonth(today.getMonth() + 3))

			return (
				<ControlledDatePicker
					locale={locale === 'de' ? deAT : enUS}
					name={api_field_name}
					label={resolveLabel(label, optional, required)}
					placeholder={t('Select Date') as string}
					hint={description}
					fromDate={start}
					toDate={end}
					captionLayout="dropdown-buttons"
					wrapperClass={clsx(verticalSpacingClass, className)}
				/>
			)
		}
		case 'email': {
			return (
				<ControlledInput
					wrapperClass={clsx(verticalSpacingClass, className, width)}
					label={resolveLabel(label, optional, required)}
					maxLength={max_length}
					name={api_field_name}
					placeholder={placeholder}
					inputMode="email"
				/>
			)
		}
		case 'phone': {
			return (
				<ControlledPhoneInput
					name={api_field_name}
					label={resolveLabel(label, optional, required)}
					wrapperClass={clsx(verticalSpacingClass, className)}
					countryCode="at"
				/>
			)
		}
		case 'checkbox': {
			if (options.length) {
				return (
					<span className="col-span-full">
						<p className="p_highlight mb-4">
							{resolveLabel(label, optional, required)}
						</p>
						<div className="grid grid-cols-3 gap-6 sm:grid-cols-2">
							{options.map((option) => {
								return (
									<ControlledCheckbox
										key={option._uid}
										wrapperClass="col-span-1"
										label={option.text}
										name={option.value}
										control={control}
									/>
								)
							}, [])}
						</div>
					</span>
				)
			} else {
				return (
					<ControlledCheckbox
						wrapperClass={clsx(
							'col-span-full mb-4 first:mt-12',
							className && className
						)}
						label={resolveLabel(label, optional, required)}
						name={api_field_name}
						control={control}
					/>
				)
			}
		}
		// case 'modal-checkbox': {
		// 	return (
		// 		<ModalCheckbox
		// 			description={description}
		// 			label={label}
		// 			api_field_name={api_field_name}
		// 			control={control}
		// 		/>
		// 	)
		// }
		case 'radio': {
			return (
				<div className={clsx(verticalSpacingClass, className)}>
					{label && (
						<span className="p_highlight mb-2 inline-block">
							{resolveLabel(label, optional, required)}
						</span>
					)}
					{options?.map(({ text, value }) => (
						<ControlledRadio
							key={value}
							label={text}
							value={value}
							name={api_field_name}
						/>
					))}
				</div>
			)
		}
		case 'select': {
			return (
				<ControlledSelect
					wrapperClass={clsx(verticalSpacingClass, className)}
					label={resolveLabel(label, optional, required)}
					name={api_field_name}
					placeholder={placeholder}
					options={options?.map(({ text, value }) => ({
						label: text,
						value,
					}))}
				/>
			)
		}
		// case 'image': {
		// 	return (
		// 		<ControlledImageUpload
		// 			name={api_field_name}
		// 			label={resolveLabel(label, optional, required)}
		// 			locale={locale}
		// 			wrapperClass={clsx('col-span-full', className)}
		// 		/>
		// 	)
		// }
		// case 'cv': {
		// 	return (
		// 		<ControlledDocumentUpload
		// 			name={api_field_name}
		// 			label={resolveLabel(label, optional, required)}
		// 			locale={locale}
		// 			isCV
		// 			wrapperClass={clsx('col-span-full mb-6 mt-12', className)}
		// 		/>
		// 	)
		// }
		// case 'documents': {
		// 	return (
		// 		<ControlledDocumentUpload
		// 			name={api_field_name}
		// 			label={resolveLabel(label, optional, required)}
		// 			locale={locale}
		// 			className={className}
		// 			wrapperClass="col-span-full mb-12"
		// 		/>
		// 	)
		// }
		case 'multiupload': {
			return (
				<ControlledMultiUpload
					name={api_field_name}
					className={clsx('mb-12', className, width || 'col-span-full')}
				/>
			)
		}
		case 'title': {
			return <FormTitle label={label} />
		}
		// case 'info': {
		// 	return (
		// 		<div className="text-gray-70 [&_*]:micro col-span-full mb-2">
		// 			{new RichTextResolverService(label).resolveRichText()}
		// 		</div>
		// 	)
		// }

		default: {
			return <Noop />
		}
	}
}

const DynamicForm = ({
	formData,
	control,
	className,
	wrapperClass,
}: DynamicFormProps) => {
	return (
		<div
			className={clsx(
				'grid auto-cols-fr grid-cols-6 md:gap-x-6 lg:gap-x-6',
				wrapperClass
			)}
		>
			{formData.map((item) => {
				if ('title' in item) {
					// const isMultiUpload = item.items.filter(
					// 	(item) => item.type === 'multiupload'
					// )
					return (
						// <TextAccordion
						// 	key={item._uid}
						// 	className="col-span-6 mb-12 sm:mb-10"
						// 	isOpened={!!isMultiUpload.length}
						// 	title={
						// 		<h4 className="subtitle_highlight sm:title">{item.title}</h4>
						// 	}
						// >
						<div
							key={item._uid}
							className="mt-6 grid w-full grid-cols-6 gap-x-6"
						>
							{item.items.map((item) => (
								<Generator
									key={item._uid}
									formData={formData}
									control={control}
									className={clsx(
										'sm:col-span-full',
										item.width || 'col-span-3',
										className
									)}
									noOptionalText
									{...item}
								/>
							))}
						</div>
						// </TextAccordion>
					)
				} else {
					return (
						<Generator
							key={item._uid}
							control={control}
							formData={formData}
							className={clsx(
								'sm:col-span-full',
								item.width || 'col-span-3',
								className
							)}
							{...item}
						/>
					)
				}
			})}
		</div>
	)
}

export default DynamicForm
