import { useCallback, useEffect, useState } from 'react'
import { useRouter } from 'next/compat/router'

export function useModal<TModal = 'default'>() {
	const [modal, setModal] = useState<null | TModal>(null)
	const { events } = useRouter() ?? {}

	const closeModal = useCallback(() => setModal(null), [])

	const openModal = useCallback((modal: TModal) => setModal(modal), [])

	useEffect(() => {
		if (!events) {
			return
		}

		const handleRouteChange = () => {
			closeModal()
		}

		events.on('routeChangeStart', handleRouteChange)
		return () => {
			events.off('routeChangeStart', handleRouteChange)
		}
	}, [closeModal, events])

	return { modal, openModal, closeModal }
}
