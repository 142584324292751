/** @type {import('next-i18next').UserConfig} */
module.exports = {
	debug: process.env.DEBUG_I18NEXT === 'true',
	cleanCode: true,
	returnObjects: true,
	saveMissing: true,
	saveMissingPlurals: true,
	keySeparator: false,
	nsSeparator: '|',
	load: 'currentOnly',
	fallbackLng: 'de',
	defaultNS: 'translation',
	ns: ['translation'],
	i18n: {
		localeDetection: false,
		defaultLocale: 'de',
		locales: ['de', 'en'],
	},
}
