import 'tailwind-config/main.css'
import { useRouter } from 'next/router'
import { Manrope } from 'next/font/google'
import clsx from 'clsx'
import { apiPlugin, storyblokInit } from '@storyblok/react'
import { Components } from '@components/common/DynamicComponents/DynamicComponent'
import { ExtendedAppProps } from './types'
import { GTM, SEO, Toast } from 'ui'
import { ToastStoreProvider, usePageTitle } from 'common'
import { appWithTranslation } from 'next-i18next'
import nextI18NextConfig from '../../next-i18next.config.js'
import Script from 'next/script'

const accessToken =
	process.env.STORYBLOK_KEY || 'used-only-to-prevent-client-console-error'

storyblokInit({
	accessToken,
	use: [apiPlugin],
	Components,
	cache: {
		clear: 'auto',
		type: 'memory',
	},
})

const font = Manrope({
	variable: '--base-font-family',
	subsets: ['latin', 'cyrillic', 'greek'],
})

function App({ Component, pageProps }: ExtendedAppProps) {
	const router = useRouter()
	const { seo, gtm, ...rest } = pageProps ?? {}
	const getLayout = Component.getLayout ?? ((page) => page)

	const pageTitle = usePageTitle('Sportsaustria')

	const url = `https://sportsaustria.trenkwalder.com${router.asPath}`

	return (
		<>
			<SEO
				seo={{
					...seo,
					site_name: seo.site_name,
					metatags: seo.metatags,
					locale: 'de-AT',
					domain: 'sportsaustria.trenkwalder.com',
					url,
				}}
				pageTitle={seo.metatags.title || pageTitle}
			/>
			<GTM gtm={gtm} />
			<Script
				strategy="afterInteractive"
				src={`https://www.google.com/recaptcha/enterprise.js?render=${process.env.NEXT_PUBLIC_RECAPTCHA_KEY}`}
			/>
			<div className={clsx(font.variable, 'font-sans')}>
				<ToastStoreProvider>
					{getLayout(<Component {...rest} />)}
					<Toast />
				</ToastStoreProvider>
				<div id="modal" />
			</div>
		</>
	)
}

export default appWithTranslation(App, nextI18NextConfig)
