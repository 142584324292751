const prefixLookup = new Map([
	['development', '.dev'],
	['staging', '.staging'],
	['production', '.prod'],
	['live', ''],
])

const environment = process.env.NEXT_PUBLIC_ENVIRONMENT

const isProduction = environment === 'production' || environment === 'live'
const isStaging = environment === 'staging'
const isDevelopment = environment === 'development'
const isLocal = environment === 'local' || !environment

const LIVE_SUBDOMAINS = [
	'group',
	'sportsaustria',
	'at',
	'de',
	'hu',
	'cz',
	'sk',
	'si',
	'ro',
	'hr',
	'gr',
	'bg',
]

const getHostByCountryCode = (code) => {
	if (!environment || environment === 'local') {
		return `${code}.localhost`
	}

	if (isProduction && LIVE_SUBDOMAINS.includes(code)) {
		return `${code}.trenkwalder.com`
	}

	const envPrefix = prefixLookup.get(environment)

	return `${code}${envPrefix}.trenkwalder.com`
}

module.exports = {
	isProduction,
	isStaging,
	isDevelopment,
	isLocal,
	getHostByCountryCode,
}
