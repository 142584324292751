import { useMemo } from 'react'
import { useRouter } from 'next/router'
import { unslugify, titleize } from '../utils'

export const usePageTitle = (countryName: string) => {
	const { asPath } = useRouter()

	const pageTitle = useMemo(() => {
		const [withoutQuery] = asPath.split('?')
		const [withoutAnchor] = withoutQuery.split('#')
		const paths = withoutAnchor.split('/').filter(Boolean)

		const currentPage = paths[paths.length - 1] ?? ''

		const pageName = titleize(unslugify(currentPage))

		const base = `${countryName} Trenkwalder`

		return [pageName, base].filter(Boolean).join(' | ')
	}, [asPath, countryName])

	return pageTitle
}
